//
// dropdowns.scss
// Extended from Bootstrap
//

//
// Bootstrap overrides ==================================
//

// Dropdown arrows
//
// Replace original arrows with Feather icons

.dropdown-toggle {
  &::after {
    width: auto;
    height: auto;
    border: none !important;
    line-height: 1;
    vertical-align: middle;
    font-family: 'Feather';
  }

  &::after {
    content: '\e92e';
  }
}

.dropup > .dropdown-toggle {
  &::after {
    content: '\e931';
  }
}

.dropend > .dropdown-toggle {
  &::after {
    content: '\e930';
  }
}

.dropleft > .dropdown-toggle {
  &::before {
    content: '\e92f';
    width: auto;
    height: auto;
    border: none !important;
    vertical-align: middle;
    font-family: 'Feather';
  }
}

// Dropdown toggle
//
// Right align arrows

.dropdown-item.dropdown-toggle {
  display: flex;
  justify-content: space-between;
}

// Dropdown menu animation
//
// Animate dropdown menu appearance

.dropdown-menu {
  animation: dropdownMenu 0.15s;
}

@keyframes dropdownMenu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

//
// Theme ===================================
//

// Dropdown ellipses
//
// Styles the ellipses icon and removes the dropdown arrow

.dropdown-ellipses {
  font-size: $font-size-lg;
  color: var(--#{$prefix}gray-400);
}

.dropdown-ellipses::after {
  display: none;
}

// Dropdown card
//
// Makes the dropdown menu act like a card

.dropdown-menu-card {
  --#{$prefix}dropdown-card-min-width: #{$dropdown-card-min-width};
  --#{$prefix}dropdown-card-border-color: #{$dropdown-card-border-color};
  --#{$prefix}dropdown-card-header-min-height: #{$dropdown-card-header-min-height};
  --#{$prefix}dropdown-card-body-max-height: #{$dropdown-card-body-max-height};
  min-width: var(--#{$prefix}dropdown-card-min-width);
  padding-top: 0;
  padding-bottom: 0;
  background-color: $card-bg;
  border-color: var(--#{$prefix}dropdown-card-border-color);

  .card-header {
    min-height: var(--#{$prefix}dropdown-card-header-min-height);
    padding: $card-spacer-y $card-spacer-x;
    border-bottom: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
  }

  .card-body {
    max-height: var(--#{$prefix}dropdown-card-body-max-height);
    padding: $card-spacer-y $card-spacer-x;
    overflow-y: auto;
  }
}

// Sizing

.dropdown-menu-sm {
  --#{$prefix}dropdown-padding-y-sm: #{$dropdown-padding-y-sm};
  --#{$prefix}dropdown-font-size-sm: #{$dropdown-font-size-sm};
  --#{$prefix}dropdown-item-padding-y-sm: #{$dropdown-item-padding-y-sm};
  --#{$prefix}dropdown-item-padding-x-sm: #{$dropdown-item-padding-x-sm};
  padding-top: var(--#{$prefix}dropdown-padding-y-sm);
  padding-bottom: var(--#{$prefix}dropdown-padding-y-sm);
  font-size: var(--#{$prefix}dropdown-font-size-sm);
}

.dropdown-menu-sm .dropdown-item {
  padding: var(--#{$prefix}dropdown-item-padding-y-sm) var(--#{$prefix}dropdown-item-padding-x-sm);
}
