@import "styles/theme.scss";

.dz-message {
  padding-top: 20px;
  padding-bottom: 20px;
}

.user-avatar {
  border: 1px solid rgb(227, 235, 246);
  padding: 7px;
  margin: 0px;
  color: rgb(110, 132, 163);
}

.cursor-pointer {
  cursor: pointer;
}